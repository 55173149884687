import * as React from 'react';

import {CategorySuggestion} from '../../search/search-suggestions/search-suggestions.class';
import {gaLegacySiteSearch} from '../../../client/ga/ga-legacy.functions';
import {pushGaEvent} from '../../../client/ga/ga.functions';
import {SignalsService} from '../../../client/search/signals/signals.service';
import {useService} from '../../react/ServiceContext';

interface SearchOverlayCategorySuggestionProps {
    categorySuggestion: CategorySuggestion;
    currentCategoryQueryID: string;
    index: number;
    recordSearchTerm: () => void;
    searchString: string;
}

const MAX_MOBILE_RESULTS = 6;

export const SearchOverlayCategorySuggestion = ({
    categorySuggestion,
    currentCategoryQueryID,
    index,
    recordSearchTerm,
    searchString,
}: SearchOverlayCategorySuggestionProps) => {
    const signalsService: SignalsService = useService(`signalsService`);

    /**
     * Template
     */
    return (
        <div className={`searchOverlay__searchCategoryResult ${index >= MAX_MOBILE_RESULTS ? 'd-lg-block d-none' : ''}`}>
            <div className="card h-100">
                <div className="card-body">
                    <a
                        className="text-decoration-none d-flex align-items-center"
                        href={categorySuggestion.url}
                        onClick={() => {
                            recordSearchTerm();
                            gaLegacySiteSearch(searchString, `typeahead_search`, true);
                            signalsService
                                .sendSignals([
                                    {
                                        params: {
                                            collection: `productCategories`,
                                            docId: categorySuggestion.legacyWebcatID,
                                            fusion_query_id: currentCategoryQueryID,
                                            res_pos: index + 1,
                                            source: `SearchOverlay`,
                                        },
                                        type: `click`,
                                    },
                                ])
                                .catch((sendSignalsErr) => {
                                    // fail silently, most likely a navigation before signals finish saving
                                    // eslint-disable-next-line no-console
                                    console.log(`Error sending fusion signals`, sendSignalsErr);
                                });
                            pushGaEvent(`ga4_search_overlay_click`, {
                                click_type: `search_overlay_categories`,
                                search_input: searchString,
                                selection_name: categorySuggestion.name,
                            });
                        }}
                    >
                        <div className="thumbnail-wrapper d-lg-block d-none float-left mr-3">
                            <img
                                alt={categorySuggestion.name}
                                height="50"
                                src={`/${categorySuggestion.imageAssetLargeThumb || 'ProductImageLargeThumbs/noimage.jpg'}`}
                                width="50"
                            />
                        </div>
                        <div className="categoryName w-100 text-wrap">
                            <span className="body">{categorySuggestion.name}</span>
                            {categorySuggestion.parentName && (
                                <div className="caption gray-400 parentName">
                                    in {categorySuggestion.parentName}{' '}
                                    {categorySuggestion.numItems && (
                                        <>
                                            {''}({categorySuggestion.numItems})
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};
