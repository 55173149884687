import {appComponent} from '../app.component';
import {ImpError} from '../imp-error/imp-error.class';

export class FetchService {
    /**
     * Performs DELETE using Fetch
     * @param url
     * @param init
     */
    public async delete<T>(url: string, init?: RequestInit): Promise<T> {
        let fetchRes: Response;
        try {
            fetchRes = await fetch(url, {
                headers: {'CSRF-Token': appComponent.csrfToken},
                method: `DELETE`,
                ...init,
            });
        } catch (fetchErr) {
            throw new ImpError(fetchErr);
        }
        if (fetchRes && !fetchRes.ok) {
            await this._handleErrorRes(fetchRes);
        }
        return fetchRes?.json();
    }

    /**
     * Performs GET using Fetch
     * @param url
     * @param init
     * @param nonJSONRes - If response is not parseable as JSON
     */
    public async get<T>(url: string, init?: RequestInit, nonJSONRes?: boolean): Promise<T> {
        let fetchRes: Response;
        try {
            fetchRes = await fetch(url, init);
        } catch (fetchErr) {
            throw new ImpError(fetchErr);
        }
        if (fetchRes && !fetchRes.ok) {
            await this._handleErrorRes(fetchRes);
        }
        if (nonJSONRes) {
            return (await fetchRes.text()) as T;
        }
        return fetchRes?.json();
    }

    /**
     * Performs POST using Fetch
     * @param url
     * @param data
     * @param nonJSONRes - If response is not parseable as JSON
     */
    public async post<T>(url: string, data?: any, nonJSONRes?: boolean): Promise<T> {
        let fetchRes: Response;
        try {
            fetchRes = await fetch(url, {
                body: JSON.stringify(data),
                headers: {'content-type': `application/json;charset=UTF-8`, 'CSRF-Token': appComponent.csrfToken},
                method: `POST`,
            });
        } catch (fetchErr) {
            throw new ImpError(fetchErr);
        }
        if (fetchRes && !fetchRes.ok) {
            await this._handleErrorRes(fetchRes);
        }
        if (nonJSONRes) {
            return (await fetchRes.text()) as T;
        }
        return fetchRes?.json();
    }

    /**
     * Performs PUT using Fetch
     * @param url
     * @param data
     */
    public async put<T>(url: string, data?: any): Promise<T> {
        let fetchRes: Response;
        try {
            fetchRes = await fetch(url, {
                body: JSON.stringify(data),
                headers: {'content-type': `application/json;charset=UTF-8`, 'CSRF-Token': appComponent.csrfToken},
                method: `PUT`,
            });
        } catch (fetchErr) {
            throw new ImpError(fetchErr);
        }
        if (fetchRes && !fetchRes.ok) {
            await this._handleErrorRes(fetchRes);
        }
        return fetchRes?.json();
    }

    /**
     * Handles fetchRes for error parsing
     * @param fetchRes
     * @private
     */
    private async _handleErrorRes(fetchRes: Response) {
        // Handle 404 custom page middleware
        if (fetchRes.status === 404) {
            throw new ImpError(fetchRes);
        }

        // Else parse JSON response
        throw new ImpError(await fetchRes.json());
    }
}
