import * as React from 'react';
import {A11y, Navigation, Pagination, Scrollbar} from 'swiper';
import {Button} from 'react-bootstrap';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useEffect, useState} from 'react';

import PromotionTracker from '../../analytics/promotion-tracker/PromotionTracker';
import {DealsService} from '../../../client/deals/deals.service';
import {Deal} from '../../deals/deal.class';
import {EmptyState} from '../../ui/EmptyState';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {InfoModal} from '../../ui/modals/InfoModal';
import {LinkButton} from '../../components/LinkButton/LinkButton';
import {OrdersService} from '../../../client/orders/orders.service';
import {PromoNotMetModal} from '../../promos/PromoNotMetModal';
import {PromosService} from '../../../client/promos/promos.service';
import {SwiperStyles} from '../../ui/Swiper/Swiper.styles';
import {useService} from '../../react/ServiceContext';

interface HeaderDealsProps {
    closeMegaMenu: () => void;
    setErrorMessage: (value: React.SetStateAction<string>) => void;
}

export const HeaderDeals = ({closeMegaMenu, setErrorMessage}: HeaderDealsProps) => {
    const [appliedPromo, setAppliedPromo] = useState(``);
    const [deals, setDeals] = useState<Deal[]>([]);
    const [loading, setLoading] = useState(true);
    const [offerDetails, setOfferDetails] = useState(``);
    const [promoNotMetDeal, setPromoNotMetDeal] = useState<Deal>(undefined);
    const componentName = `HeaderDeals`;
    const dealsService: DealsService = useService(`dealsService`);
    const ordersService: OrdersService = useService(`ordersService`);
    const promosService: PromosService = useService(`promosService`);

    // Get Deals
    useEffect(() => {
        dealsService
            .getDeals()
            .then((getDealsRes) => {
                setDeals(getDealsRes);

                // Get currentOrder to determine if appliedPromo
                ordersService
                    .getCurrentOrder()
                    .then((getCurrentOrderRes) => {
                        setLoading(false);
                        setAppliedPromo(getCurrentOrderRes?.pcode || ``);

                        // Subscribe to currentOrder$ for apply promo logic
                        ordersService.currentOrder$.subscribe({
                            next: (currentOrder) => {
                                setAppliedPromo(currentOrder?.pcode || ``);
                            },
                        });
                    })
                    .catch(() => {
                        errorSilently();
                    });
            })
            .catch(() => {
                errorSilently();
            });
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Applies selected promo code to an order
     * @param deal - Promo to apply to order
     */
    const applyPromoCode = (deal: Deal) => {
        setErrorMessage(``);
        promosService.applyPromoCode(deal.pcode, componentName).subscribe({
            error: (applyPromoCodeErr: ImpError) => {
                if (applyPromoCodeErr.message.indexOf(`Promo code not valid`) >= 0) {
                    setPromoNotMetDeal(deal);
                } else {
                    setErrorMessage(applyPromoCodeErr.message);
                }
            },
        });
    };

    /**
     * On error renders empty state
     */
    const errorSilently = () => {
        setLoading(false);
        setDeals([]);
    };

    /**
     * Returns specific action button based on deal contents
     * @param deal
     */
    const getActionButton = (deal: Deal) => {
        if (deal.customUrl) {
            return (
                <Button
                    href={deal.customUrl}
                    title="Shop Now"
                    variant="outline-secondary"
                >
                    Shop Now
                </Button>
            );
        } else if (deal.hasLandingPage) {
            return (
                <Button
                    href={`/deals/${deal.campaignId}`}
                    title="Shop Now"
                    variant="outline-secondary"
                >
                    Shop Now
                </Button>
            );
        } else {
            return (
                <Button
                    href={`/special/${deal.specialNumber}`}
                    title="Shop Now"
                    variant="outline-secondary"
                >
                    Shop Now
                </Button>
            );
        }
    };

    /**
     * Returns content of HeaderDeals
     */
    const getHeaderDealsContent = () => {
        if (loading) {
            return <EmptyState message={<div className="spinner" />} />;
        }
        if (deals.length === 0) {
            return <EmptyState message="Sorry, there are no deals available at this time." />;
        }
        return (
            <SwiperStyles>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    navigation={true}
                    slidesPerGroup={3}
                    slidesPerView={3}
                    spaceBetween={16}
                    watchSlidesProgress={true}
                >
                    {deals.map((deal, index) => (
                        <SwiperSlide key={index}>
                            <>
                                {deal.campaignImage.url ? (
                                    <img
                                        className="img-fluid"
                                        alt={deal.image_alt_text}
                                        height={deal.campaignImage.height}
                                        src={deal.campaignImage.url}
                                        width={deal.campaignImage.width}
                                    />
                                ) : (
                                    <div className="tw-flex tw-justify-center">
                                        <img
                                            alt="No Image Available"
                                            className="tw-h-[106px]"
                                            src="/dimg/noimage.png"
                                        />
                                    </div>
                                )}
                                <h5>{deal.campaignName}</h5>
                                {deal.beginDate && deal.endDate && deal.displayGoodThroughDates === `Yes` && (
                                    <div>
                                        Valid: {deal.beginDate} thru {deal.endDate}
                                    </div>
                                )}
                                {deal.offerDetails && (
                                    <PromotionTracker
                                        promotion={{
                                            creative_name: `Header Deal`,
                                            creative_slot: 1,
                                            promotion_id: deal.campaignId,
                                            promotion_name: `${deal.campaignName} - Offer Details`,
                                        }}
                                    >
                                        <LinkButton
                                            onClick={() => setOfferDetails(deal.offerDetails)}
                                            text="Offer Details"
                                        />
                                    </PromotionTracker>
                                )}
                                <div className="tw-flex tw-flex-row tw-gap-2 tw-mt-4 tw-mb-6">
                                    {deal.pcode && (
                                        <PromotionTracker
                                            promotion={{
                                                creative_name: `Header Deal`,
                                                creative_slot: 2,
                                                promotion_id: deal.campaignId,
                                                promotion_name: `${deal.campaignName} - Apply Code`,
                                            }}
                                        >
                                            <Button
                                                disabled={deal.pcode === appliedPromo}
                                                onClick={() => applyPromoCode(deal)}
                                                variant="secondary"
                                            >
                                                {deal.pcode === appliedPromo ? `Code Applied` : `Apply Code`}
                                            </Button>
                                        </PromotionTracker>
                                    )}
                                    <PromotionTracker
                                        promotion={{
                                            creative_name: `Header Deal`,
                                            creative_slot: 3,
                                            promotion_id: deal.campaignId,
                                            promotion_name: `${deal.campaignName} - Shop Now`,
                                        }}
                                    >
                                        {getActionButton(deal)}
                                    </PromotionTracker>
                                </div>
                            </>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SwiperStyles>
        );
    };

    /**
     * Template
     */
    return (
        <div className="container-xl">
            <div className="tw-text-right">
                <button
                    className="btn btn-link tw-py-3"
                    onClick={closeMegaMenu}
                >
                    <FontAwesomeIcon
                        className="gray-400"
                        icon={faTimes}
                        size="4x"
                    />
                </button>
            </div>
            {getHeaderDealsContent()}
            <InfoModal
                message={offerDetails}
                modalTitle="Offer Details"
                onClose={() => setOfferDetails(``)}
                show={!!offerDetails}
            />
            <PromoNotMetModal
                campaignId={promoNotMetDeal?.campaignId}
                hasLandingPage={promoNotMetDeal?.hasLandingPage}
                onClose={() => setPromoNotMetDeal(undefined)}
                show={!!promoNotMetDeal}
            />
        </div>
    );
};
