import {CookieName} from '../../shared/cookies/cookie.class';
import {FetchService} from '../fetch/fetch.service';

export class CookiesService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Creates a cookie using specified name/value pair
     * @param name - Name of the cookie
     * @param value - Value associated with the name
     * @param lifeInDays - How many days before the cookie expires
     * @param lifeInMinutes - How many minutes before the cookie expires
     * if not specified, it has no expiration
     */
    public createCookie(name: CookieName, value: string, lifeInDays?: number, lifeInMinutes?: number) {
        let expires = ``;

        // If value undefined, delete cookie (avoid saving string 'undefined')
        if (value === undefined) {
            value = ``;
            lifeInDays = -1;
        }

        // Set expiration date if provided
        if (lifeInDays || lifeInMinutes) {
            const expirationDate = new Date();
            let lifeInMs = 0;
            if (lifeInDays) {
                lifeInMs += lifeInDays * 24 * 60 * 60 * 1000;
            }
            if (lifeInMinutes) {
                lifeInMs += lifeInMinutes * 60 * 1000;
            }
            expirationDate.setTime(expirationDate.getTime() + lifeInMs);
            expires = `; expires=${expirationDate.toUTCString()}`;
        }

        // Create cookie
        document.cookie = `${name}=${value + expires}; path=/`;
    }

    /**
     * TBD
     */
    public async deleteTrackingCookies() {
        const cookies = await this._getTrackingCookies();
        if (cookies?.length > 0) {
            for (const cookie of cookies) {
                // Tracking cookie names are dynamic, cast as CookieName to satisfy TS
                this.createCookie(cookie as CookieName, ``, -1);
            }
        }
    }

    /**
     * Erases the specified cookie name (sets value to blank
     * and expiration date as a negative value)
     * @param name - Name of the cookie you wish to erase
     */
    public eraseCookie(name: CookieName) {
        this.createCookie(name, ``, -1);
    }

    /**
     * Returns value for provided CookieName
     * @param name - Name of cookie to return value from
     */
    public getCookie(name: CookieName): string {
        const nameEquals = name + `=`;
        const cookieArray = document.cookie.split(`;`);
        for (let i = 0; i < cookieArray.length; i++) {
            let elem = cookieArray[i];
            while (elem.charAt(0) === ` `) {
                elem = elem.substring(1, elem.length);
            }
            if (elem.indexOf(nameEquals) === 0) {
                return elem.substring(nameEquals.length, elem.length);
            }
        }

        // Else return empty string
        return ``;
    }

    /**
     * TBD
     * @private
     */
    private _getTrackingCookies() {
        return this._fetchService.get<string[]>(`/api/cookies/getCookiesOneTrust`);
    }
}
